import { FC } from "react";

type props = {
  fill: string;
};

export const ClockIcon: FC<props> = ({ fill }) => (
  <svg viewBox="0 0 20 21" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0.5C8.02219 0.5 6.08879 1.08649 4.4443 2.1853C2.79981 3.28412 1.51809 4.8459 0.761209 6.67317C0.00433284 8.50043 -0.193701 10.5111 0.192152 12.4509C0.578004 14.3907 1.53041 16.1725 2.92894 17.5711C4.32746 18.9696 6.10929 19.922 8.0491 20.3079C9.98891 20.6937 11.9996 20.4957 13.8268 19.7388C15.6541 18.9819 17.2159 17.7002 18.3147 16.0557C19.4135 14.4112 20 12.4778 20 10.5C20 9.18678 19.7413 7.88642 19.2388 6.67317C18.7363 5.45991 17.9997 4.35752 17.0711 3.42893C16.1425 2.50035 15.0401 1.76375 13.8268 1.2612C12.6136 0.758658 11.3132 0.5 10 0.5ZM10 18.5C8.41775 18.5 6.87104 18.0308 5.55544 17.1518C4.23985 16.2727 3.21447 15.0233 2.60897 13.5615C2.00347 12.0997 1.84504 10.4911 2.15372 8.93928C2.4624 7.38743 3.22433 5.96197 4.34315 4.84315C5.46197 3.72433 6.88743 2.9624 8.43928 2.65372C9.99113 2.34504 11.5997 2.50346 13.0615 3.10896C14.5233 3.71447 15.7727 4.73984 16.6518 6.05544C17.5308 7.37103 18 8.91775 18 10.5C18 12.6217 17.1572 14.6566 15.6569 16.1569C14.1566 17.6571 12.1217 18.5 10 18.5ZM13.1 11.13L11 9.92V5.5C11 5.23478 10.8946 4.98043 10.7071 4.79289C10.5196 4.60536 10.2652 4.5 10 4.5C9.73479 4.5 9.48043 4.60536 9.2929 4.79289C9.10536 4.98043 9 5.23478 9 5.5V10.5C9 10.5 9 10.58 9 10.62C9.00593 10.6889 9.02281 10.7564 9.05 10.82C9.0706 10.8793 9.09741 10.9363 9.13 10.99C9.15737 11.0468 9.1909 11.1005 9.23 11.15L9.39001 11.28L9.48 11.37L12.08 12.87C12.2324 12.9564 12.4048 13.0012 12.58 13C12.8014 13.0015 13.0171 12.9296 13.1932 12.7953C13.3693 12.6611 13.4959 12.4722 13.5531 12.2583C13.6103 12.0444 13.5948 11.8176 13.5092 11.6134C13.4236 11.4092 13.2726 11.2392 13.08 11.13H13.1Z" />
  </svg>
);
